import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import userStore from '../../stores/UserStore';
import webAppStore from '../../stores/WebAppStore';
import ReferralItem from '../../components/ReferralItem/ReferralItem';
import { ReactComponent as CopyIcon } from '../../images/copy.svg';
import { ReactComponent as UpdateIcon } from '../../images/update.svg';
import { ReactComponent as InfoIcon } from '../../images/Info.svg';
import styles from './ReferralScreen.module.scss';

const ReferralScreen = observer(() => {
  const hasReferrals = userStore.Referrals.length > 0;
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const loadReferrals = async () => {
    try {
      setIsLoading(true);
      await userStore.fetchReferrals();
    } catch (error) {
      console.error('Ошибка загрузки рефералов:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    webAppStore.updateBackButtonVisibility();
    loadReferrals();
  }, []);

  const copuFn = (text) => {
    navigator.clipboard.writeText(text)
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }
  return (
    <div className={styles.referralScreen}>
      {isCopied && <div className={styles.copyNotification}>Ссылка скопирована</div>}
      <div className={styles.content}>
        <div className={styles.headerRow}>
          <h1>Рефералы</h1>
          <div className={styles.infoIconBlock}>
            <InfoIcon />
          </div>
        </div>
        <div className={styles.referralHeader}>
          <div className={`${styles.referralCode} ${styles.referralCodeBalance}`}>
            <span>{userStore.refBalance} USDT</span>
          </div>
          <div className={styles.referralCode}>
            <input type="text" value={userStore.ReferralCode} readOnly />
            <button
              className={styles.copyButton}
              onClick={() => copuFn(`https://t.me/Foex_Pay_Bot?start=${userStore?.ReferralCode}`)}
            >
              <div className={styles.copyIcon}>
                <CopyIcon />
              </div>
            </button>
          </div>
          <div className={styles.referralTop}>
            <div className={styles.referralTitle}>
              Топ друзей
              <UpdateIcon style={{ marginLeft: '10px', alignSelf: 'center' }} />
            </div>
            <div className={styles.referralTitleRight}>Получено</div>
          </div>
        </div>

        {!hasReferrals && (
          <div className={styles.layout}>
            <div className={styles.emptyState}>
              <div className={styles.image}></div>
              <div className={styles.emptyText}>
                Тут будет список <br /> приглашенных друзей
              </div>
            </div>
          </div>
        )}

        {hasReferrals && (
          <div className={styles.referralsList}>
            {userStore.referrals.map((referral) => (
              <ReferralItem key={referral.id} name={referral.name} amount={referral.amount} balance={referral.balance} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default ReferralScreen;