import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import RequestItem from '../../components/RequestItem/RequestItem';
import requestStore from '../../stores/RequestStore';
import webAppStore from '../../stores/WebAppStore';
import styles from './RequestsScreen.module.scss';

const RequestsScreen = observer(() => {
  useEffect(() => {
    requestStore.fetchRequests();

    webAppStore.updateBackButtonVisibility();
  }, []);

  return (
    <div className={styles.requestsScreen}>
      <div className={styles.content}>
        <h1>Заявки</h1>
        <div className={styles.requestsList}>
          {requestStore.requests.map((request) => (
            <RequestItem
              key={request.id}
              number={request.id}
              type={request.type}
              amount={request.amount}
              status={request.status}
              date={request.date}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default RequestsScreen;