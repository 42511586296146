import React from 'react';
import styles from './ReferralItem.module.scss';

const ReferralItem = ({ name, username, date, balance }) => {
  return (
    <div className={styles.referralItem}>
      <div className={styles.userInfo}>
        <div className={styles.userAvatar} />
        <div className={styles.userName}>{name}</div>
      </div>
      <div className={styles.balance}>+{balance} USDT</div>
      <div className={styles.amount}>{date}</div>
    </div>
  );
};

export default ReferralItem;