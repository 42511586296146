import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import RequestItem from '../RequestItem/RequestItem';
import requestStore from '../../stores/OldRequestStore';
import webAppStore from '../../stores/WebAppStore';
import './RequestHistory.scss';

const RequestHistory = observer(() => {
  useEffect(() => {
    if (requestStore.activeRequests.length === 0) {
      requestStore.fetchConvRequests();
      requestStore.fetchWedRequests();
      requestStore.fetchInvoiceRequests();
    }
    webAppStore.updateBackButtonVisibility();
  }, []);

  return (
    <div className="request-screen">
      <div className="layout"></div>
      <div className="content">
        <h1>История заявок</h1>
        <div className="requests-list">
          {requestStore.RequestHistory.map((request) => (
            <RequestItem
              key={request.number}
              number={request.number}
              type={request.type}
              amount={request.amount}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default RequestHistory;