import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import Footer from '../../components/Footer/Footer';
import NotificationModal from '../../components/NotificationModal/NotificationModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import webAppStore from '../../stores/WebAppStore';
import { ReactComponent as FrameIcon } from '../../images/Frame.svg';
import './ServicesScreen.scss'
import userStore from '../../stores/UserStore';

const ServicesScreen = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showNotification, setShowNotification] = useState(false);

  const paths = [
    '/requests',
    '/ved',
    '/invoice-payment',
    '/aml-check',
  ];
  const buttonLabels = [
    'Обмен USDT',
    'Платежный агент',
    'Оплатить инвойс',
    'AML проверка',
  ];
  useEffect(() => {
    const initializeMenu = async () => {
      searchParams.delete("error-background");
      webAppStore.updateBackButtonVisibility();
      try {
        await userStore.fetchUserData();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          userStore.isNewUser = true;
          setShowNotification(true);
        }
      }
      setSearchParams(searchParams);
    };

    initializeMenu();
  }, []);

  return (
    <div className='menuPage'>
      <div className='layout'>

      </div>
      <div className='headerBlock'>
        <h1 className='headerName'>Foex Pay</h1>
      </div>
      <div className='buttonsBlock'>
        {paths.map((path, index) => (
          <Button
            key={index}
            type="btnOne"
            icon={<FrameIcon />}
            label={buttonLabels[index]}
            iconPosition="right"
            onClick={() => {
              // ваша логика:
              if (userStore.isNewUser && index !== 4) {
                setShowNotification(true);
              } else {
                navigate(path);
              }
            }}
          />
        ))}
      </div>
      <Footer />
      <NotificationModal
        isOpen={showNotification}
        onClose={() => setShowNotification(false)}
      >
        Для пользования сервисом необходимо заполнить информацию в личном кабинете
      </NotificationModal>
    </div>
  );
};

export default ServicesScreen;
