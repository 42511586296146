import React from 'react';
import './Footer.scss';
const Footer = () => {
  return (
    <footer className='footerBlock'>
      <p>Copyright © 2024 4EXservice.</p>
      <p> Все права защищены.</p>
    </footer>
  );
};

export default Footer;