import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useNavigate } from 'react-router-dom';
import SelectDialog from '../../components/SelectDialog/SelectDialog';
import userStore from '../../stores/UserStore';
import webAppStore from '../../stores/WebAppStore';
import { ReactComponent as Dropdown } from '../../images/dropDown.svg';
import styles from './ProfileScreen.module.scss';

const ProfileScreen = observer(() => {
  const navigate = useNavigate();
  const [phoneEmailError, setPhoneEmailError] = useState('');

  useEffect(() => {
    const initialize = async () => {
      try {
        await userStore.fetchUserData();
      } catch (error) {
        console.error('Ошибка загрузки данных пользователя:', error);
      }
    };
    initialize();
  }, []);

  const toggleEdit = () => {
    if (!validateForm()) {
      return;
    }
    saveChanges();
  };
  const validateForm = () => {
    if (!userStore.isValidEmail(userStore.email)) {
      setPhoneEmailError('Неверный формат email');
      return false;
    }
    if (!userStore.isValidPhone(userStore.phone)) {
      setPhoneEmailError('Неверный формат номера телефона');
      return false;
    }
    if (!userStore.country) {
      setPhoneEmailError('Выберите страну');
      return false;
    }
    if (!userStore.city) {
      setPhoneEmailError('Выберите город');
      return false;
    }
    setPhoneEmailError('');
    return true;
  };
  const saveChanges = async () => {
    await userStore.update();
  };
  const showCountrySelector = () => {
    userStore.showCountryChangeBlock = !userStore.showCountryChangeBlock;
  };

  const showCitySelector = () => {
    if (userStore.country) {
      userStore.showCityChangeBlock = !userStore.showCityChangeBlock;
    }
  };

  const handleCountrySelect = (newCountry) => {
    const selectedCountry = userStore.countries.find(c => c.value === newCountry);
    userStore.Country = selectedCountry;
    userStore.City = null;

  };

  const handleCitySelect = (newCity) => {
    const cityObject = userStore?.cities.find(c => c.value === newCity);
    userStore.City = cityObject;
  };

  return (
    <div className={styles.profileScreen}>
      <div className={styles.avatar}></div>

      <input
        type="text"
        placeholder="ФИО"
        className={styles.input}
        value={userStore.Fio}
        onChange={(e) => userStore.setFio(e.target.value)}
      />

      <input
        type="text"
        placeholder="Телефон"
        className={styles.input}
        value={userStore.Phone}
        onChange={(e) => userStore.setPhone(e.target.value)}

      />

      <input
        type="text"
        placeholder="Email"
        className={styles.input}
        value={userStore.Email}
        onChange={(e) => userStore.setEmail(e.target.value)}
      />

      <div
        className={`${styles.selector}`}
        onClick={() => userStore.showCountryChangeBlock = true}
      >
        <input
          type="text"
          placeholder="Страна"
          value={userStore.Country?.label || ''}
          readOnly
          className={`${styles.input} ${styles.inputSelec}`}
        />
        <Dropdown className={styles.dropdownIcon} />
      </div>

      <div
        className={`${styles.selector} ${!userStore.Country ? styles.disabled : ''}`}
        onClick={() => userStore.showCityChangeBlock = true}
      >
        <input
          type="text"
          placeholder="Город"
          value={userStore.City?.label || ''}
          readOnly
          className={`${styles.input} ${styles.inputSelec}`}
        />
        <Dropdown className={styles.dropdownIcon} />
      </div>

      {phoneEmailError && <div className={styles.error}>{phoneEmailError}</div>}

      {userStore.showCountryChangeBlock && (
        <SelectDialog
          isOpen
          items={userStore.Countries}
          title="Выберите страну"
          onSelect={handleCountrySelect}
          onClose={() => userStore.showCountryChangeBlock = false}
        />
      )}

      {userStore.showCityChangeBlock && (
        <SelectDialog
          isOpen
          items={userStore.availableCities}
          title="Выберите город"
          onSelect={handleCitySelect}
          onClose={() => userStore.showCityChangeBlock = false}
        />
      )}
    </div>
  );
});
export default ProfileScreen;
