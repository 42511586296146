import React from 'react';
import styles from './Icon.module.scss';

function Icon({ src, alt = '' }) {
    return (
        <img
            className={styles.icon}
            src={src}
            alt={alt}
        />
    );
}

export default Icon;
