import { makeAutoObservable, toJS } from 'mobx';
import $api from '../http';
import { ReactComponent as RusFlag } from '../images/rusFlag.svg';
import { ReactComponent as AedFlag } from '../images/aedFlag.svg';
class UserStore {
  id = null;
  isNewUser = false;
  username = '';
  balance = 0;
  refBalance = 0;
  fio = '';
  phone = '';
  email = '';
  city = null;
  cityId = null;
  country = null;
  showCityChangeBlock = false;
  showCountryChangeBlock = false;
  isEditingPhone = false;
  isEditingEmail = false;
  inviter = '';
  countries = null;
  /*countries = [
    { 
      value: 'RU', 
      label: 'Россия',
      icon: <RusFlag/>, 
    },
    { 
      value: 'AED', 
      label: 'ОАЭ',
      icon: <AedFlag/>, 
    },
  ];*/
  cities = null;
  /*cities = [ 
    { value: 'Moscow', label: 'Москва', country: 'RU' },
    { value: 'Saint Petersburg', label: 'Санкт-Петербург', country: 'RU' },
    { value: 'Kazan', label: 'Казань', country: 'RU' },
    { value: 'Novosibirsk', label: 'Новосибирск', country: 'RU' },
    { value: 'Yekaterinburg', label: 'Екатеринбург', country: 'RU' },
    { value: 'Dubai', label: 'Дубай', country: 'AED' },
  ];*/
  referrals = [
    {
      id: '1',
      name: 'Иван Иванов',
      username: '@ivan',
      date: '26.01.2025',
      balance: 100.5,
    },
    {
      id: '2',
      name: 'Петр Петров',
      username: '@petr',
      date: '25.01.2025',
      balance: 75.0,
    },
    {
      id: '3',
      name: 'Алексей Алексеев',
      username: '@alexey',
      date: '24.01.2025',
      balance: 50.0,
    },
  ];
  referralCode = '';

  constructor() {
    makeAutoObservable(this);
  }
  async update() {
    try {
      if (this.isNewUser) {
        await this.createUserData();
      }
      else {
        await this.updateUserData();
      }
    } catch (error) {
      console.error('Ошибка при обновлении данных пользователя:', error);
      throw error;
    }
  }
  async fetchUserData() {
    try {
      if (this.countries === null && this.cities === null) {
        const locationsResponse = await $api.get('/countrys');
        const locations = locationsResponse.data;
        const { countries, cities } = this.transformLocations(locations);
        this.Countries = countries;
        this.Cities = cities;
      }
      const response = await $api.get('/user/me');
      const { telegram_id, id, telegram_username, telegram_email, fio, city, phone_number, currency_pairs, inviter, balance } = response.data;
      this.Id = telegram_id;
      this.ReferralCode = id;
      this.InviterId = inviter;
      this.Username = telegram_username;
      this.Email = telegram_email;
      this.Balance = balance;
      this.Fio = fio;
      this.Phone = phone_number;

      this.initializeCityAndCountry(city);
    } catch (error) {
      console.error('Ошибка при загрузке данных существующего пользователя:', error);
      throw error;
    }
  }
  async createUserData() {
    try {
      const data = {
        "telegram_username": window?.Telegram?.WebApp?.initDataUnsafe?.user?.username,
        "fio": this.Fio,
        "telegram_email": this.Email,
        "phone_number": this.Phone,
        "city_id": this.CityId,
      }
      const response = await $api.post('/user/create_user', data);
      console.log(response.data)
    } catch (error) {
      console.error('Ошибка при загрузке данных нового пользователя:', error);
      throw error;
    }
  }
  async updateUserData() {
    try {
      const data = {
        "telegram_username": window?.Telegram?.WebApp?.initDataUnsafe?.user?.username,
        "fio": this.Fio,
        "telegram_email": this.Email,
        "phone_number": this.Phone,
        "city_id": this.CityId,
      }
      const response = await $api.put('/user/user', data);
    } catch (error) {
      console.error('Ошибка при загрузке данных пользователя:', error);
      throw error;
    }
  }
  async fetchReferrals() {
    try {
      const response = await $api.get('/user/inviteds');
      const referrals = response.data.map(referral => ({
        id: referral.id,
        name: referral.fio || referral.telegram_username,
        username: referral.telegram_username,
        date: new Date(referral.created_at).toLocaleDateString(),
        balance: referral.balance || 0,
      }));
      this.setReferrals(referrals);
      return referrals;
    } catch (error) {
      console.error('Ошибка при загрузке рефералов:', error);
      throw error;
    }
  }
  initializeCityAndCountry(cityData) {
    // Если cityData пустой или null, ничего не делаем
    if (!cityData || !this.cities || !this.countries) return;

    // Находим город в списке городов
    const cityObject = this.cities.find(c =>
      // Проверяем, что cityData это объект с id и name
      (typeof cityData === 'object' && cityData.id && c.id === cityData.id) ||
      // Или проверяем по имени города
      (typeof cityData === 'object' && cityData.name && c.label.toLowerCase() === cityData.name.toLowerCase())
    );

    if (cityObject) {
      // Находим соответствующую страну
      const countryObject = this.countries.find(c => c.value === cityObject.country);

      if (countryObject) {
        // Устанавливаем сначала страну, потом город
        this.Country = countryObject;
        this.City = cityObject;
      }
    }
  }
  setReferralCode(code) {
    this.referralCode = code;
  }

  setReferrals(referrals) {
    this.referrals = referrals;
  }

  get availableCities() {
    return this.Country ? this.Cities.filter(city => city.country === this.Country.value) : [];
  }
  setCountry(newCountry) {
    if (!newCountry) {
      this.Country = null;
      this.cityId = null;
      this.City = null;
      return;
    }

    let countryObject;

    // Если передан объект
    if (typeof newCountry === 'object') {
      countryObject = newCountry;
    }
    // Если передана строка или число
    else {
      countryObject = this.countries?.find(country =>
        (typeof newCountry === 'number' && country.id === newCountry) ||
        (typeof newCountry === 'string' && country.value.toLowerCase() === newCountry.toLowerCase())
      );
    }

    if (countryObject) {
      this.Country = countryObject;

      // Сбрасываем город, если он не из этой страны
      if (this.city && this.city.country !== countryObject.value) {
        this.City = null;
        this.cityId = null;
      }
    }
  }
  setEmail(newEmail) {

    this.email = newEmail.trim();
  }

  setFio(value) {
    const words = value.toLowerCase().split(' ');
    const formattedFio = words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    this.Fio = formattedFio;
  }
  set Fio(value) {
    this.fio = value;
  }
  get Fio() {
    return toJS(this.fio);
  }
  setPhone(newPhone) {
    let filteredPhone = newPhone.replace(/[^0-9+]/g, '');

    /*
    // Добавляем + в начало, если его нет
    if (filteredPhone.length > 0 && filteredPhone[0] !== '+') {
      filteredPhone = '+' + filteredPhone;
    }*/
    if (filteredPhone.startsWith('8')) {
      filteredPhone = '+7' + filteredPhone.slice(2);
    }

    this.phone = filteredPhone.slice(0, 15);
  }
  isValidPhone(phone) {
    const russianPhoneRegex = /^\+7\d{10}$/;
    const aedPhoneRegex = /^\+971\d{9}$/;
    return russianPhoneRegex.test(phone) || aedPhoneRegex.test(phone);
  }
  isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  togglePhoneEdit() {
    this.isEditingPhone = !this.isEditingPhone;
  }
  toggleEmailEdit() {
    this.isEditingEmail = !this.isEditingEmail;
  }
  set UserName(value) {
    this.username = value;
  }
  get UserName() {
    return toJS(this.username);
  }
  set Email(value) {
    this.email = value;
  }
  get Email() {
    return toJS(this.email);
  }
  set Phone(value) {
    this.phone = value;
  }
  get Phone() {
    return toJS(this.phone);
  }
  get Country() {
    return toJS(this.country);
  }

  set Country(value) {
    this.country = value;
  }
  set City(newCity) {
    if (!newCity) {
      this.cityId = null;
      this.city = null;
      return;
    }

    // Если передан объект города
    if (typeof newCity === 'object') {
      if (newCity.id && newCity.name) {
        this.cityId = newCity.id;
        this.city = newCity;
      } else if (newCity.id && newCity.country && newCity.label) {
        this.cityId = newCity.id;
        this.city = newCity;
      }
      return;
    }

    // Если передана строка или число
    const cityObject = this.cities?.find(city =>
      (typeof newCity === 'number' && city.id === newCity) ||
      (typeof newCity === 'string' && city.label.toLowerCase() === newCity.toLowerCase())
    );

    if (cityObject) {
      this.cityId = cityObject.id;
      this.city = cityObject;
    }
  }
  get City() {
    return toJS(this.city);
  }
  get CityId() {
    return this.cityId;
  }
  get Cities() {
    return toJS(this.cities);
  }
  set Cities(value) {
    this.cities = value;
  }
  set Countries(value) {
    this.countries = value;
  }
  get Countries() {
    return toJS(this.countries);
  }
  set ShowCityChangeBlock(value) {
    this.showCityChangeBlock = value;
  }
  get ShowCityChangeBlock() {
    return toJS(this.showCityChangeBlock);
  }
  set Inviter(value) {
    this.inviter = value;
  }
  get Inviter() {
    return toJS(this.inviter);
  }
  set Referrals(value) {
    this.referrals = value;
  }
  get Referrals() {
    return toJS(this.referrals);
  }
  set ReferralCode(value) {
    this.referralCode = value;
  }
  get ReferralCode() {
    return toJS(this.referralCode);
  }
  transformLocations = (locations) => {
    const countries = [];
    const cities = [];

    const countryFlagMap = {
      'Россия': { value: 'RU', icon: <RusFlag /> },
      'ОАЭ': { value: 'AED', icon: <AedFlag /> }
    };

    locations.forEach(location => {
      // Определяем код страны и иконку динамически
      const countryData = countryFlagMap[location.name] || {
        value: location.id.toString(),
        icon: null
      };

      countries.push({
        value: countryData.value,
        label: location.name,
        icon: countryData.icon,
        id: location.id
      });

      // Динамическая трансформация городов
      location.citys.forEach(city => {
        cities.push({
          value: city.name
            .toLowerCase()
            .replace(/\s+/g, '-')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
          label: city.name,
          country: countryData.value,
          id: city.id
        });
      });
    });

    return { countries, cities };
  };
}
export default new UserStore();
