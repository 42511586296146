import React from 'react';
import styles from './RequestItem.module.scss';

const RequestItem = ({ number, type, amount, status, date }) => {
  const getStatusLabel = (status) => {
    switch (status) {
      case 0:
        return 'В обработке';
      case 1:
        return 'Отклонена';
      case 2:
        return 'Завершено';
      default:
        return 'Неизвестный статус';
    }
  };
  return (
    <div className={styles.requestItem}>
      <div className={styles.requestInfo}>
        <div className={styles.requestTitle}>{number}</div>
        <div className={styles.requestAmount}>{amount}</div>
      </div>
      <div className={styles.requestType}>{type}</div>
      <div className={styles.requestStatInfo}>
        <div className={`${styles.requestStatus} ${styles[`status${status}`]}`}>
          {getStatusLabel(status)}
        </div>
        <div className={styles.requestDate}>
          {date}
        </div>
      </div>
    </div>
  );
};

export default RequestItem;