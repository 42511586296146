import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import MoneyInput from '../MoneyInput/MoneyInput';
import WalletInput from '../WalletInput/WalletInput';
import NotificationModal from '../NotificationModal/NotificationModal';
import requestStore from '../../stores/OldRequestStore';
import webAppStore from '../../stores/WebAppStore';
import './Exchange.scss';
const Exchange = observer(() => {

  const [isAgreed, setIsAgreed] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (requestStore.RequestSuccess) {
      setShowNotification(true);
      const timer = setTimeout(() => {
        setShowNotification(false);
        requestStore.resetSuccessState();
        navigate('/');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [requestStore.RequestSuccess]);

  const getFilteredCurrencies = (isReceiving) => {
    return requestStore.availableCurrencies.map(group => ({
      ...group,
      currencies: group.currencies.filter(currency =>
        requestStore.canSelectCurrency(currency, isReceiving ? 'to' : 'from')
      )
    })).filter(group => group.currencies.length > 0);
  };

  const handleSwitch = () => {
    requestStore.switchCurrencies();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    requestStore.createRequestExchange();
  };

  useEffect(() => {
    webAppStore.updateBackButtonVisibility();
    requestStore.fetchAvailableCurrencies();
  }, []);

  const isFormValid = requestStore.currencyFrom &&
    requestStore.currencyTo &&
    requestStore.amountFrom &&
    requestStore.amountTo &&
    isValid &&
    isAgreed;

  return (
    <div className='requestPage'>
      <div className='layout'></div>

      <div className='exchangeBlock'>
        <div className='recieveOrGiveBlock'>
          <div className='labelDescription'>Вы получаете</div>
          <MoneyInput
            value={requestStore.AmountTo}
            onChange={(value) => requestStore.updateAmountTo(value)}
            currency={requestStore.CurrencyTo}
            onCurrencySelect={(currency) => requestStore.setCurrencyTo(currency)}
            availableCurrencies={getFilteredCurrencies(true)}
            isReceiving={true}
          />
        </div>
        <div className='switch' onClick={handleSwitch}>
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none">
            <path d="M11.9498 7.94975L10.5356 9.36396L8.00079 6.828L8.00004 20H6.00004L6.00079 6.828L3.46451 9.36396L2.05029 7.94975L7.00004 3L11.9498 7.94975ZM21.9498 16.0503L17 21L12.0503 16.0503L13.4645 14.636L16.0008 17.172L16 4H18L18.0008 17.172L20.5356 14.636L21.9498 16.0503Z" fill="white" />
          </svg>
        </div>
        <div className='recieveOrGiveBlock'>
          <div className='labelDescription'>Вы отдаете</div>
          <MoneyInput
            value={requestStore.AmountFrom}
            onChange={(value) => requestStore.updateAmountFrom(value)}
            currency={requestStore.CurrencyFrom}
            onCurrencySelect={(currency) => requestStore.setCurrencyFrom(currency)}
            availableCurrencies={getFilteredCurrencies(false)}
            isReceiving={false}
          />
        </div>
      </div>
      <div className='walletBlock'>
        <WalletInput
          value={requestStore.Wallet}
          onChange={requestStore.setWallet}
          onValidationChange={setIsValid}
          inputType={requestStore.getCurrencyType(requestStore.CurrencyTo)}
        />
      </div>
      <div className='agreementBlock'>
        <label>
          <input
            type="checkbox"
            checked={isAgreed}
            onChange={(e) => setIsAgreed(e.target.checked)}
          />
          Я согласен с условиями
        </label>
      </div>
      <div className='buttonsBlock'>
        <Button type="btnTwo" onClick={onSubmit} disabled={!isFormValid}>
          Отправить
        </Button>

      </div>
      <NotificationModal
        isOpen={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <p>{requestStore.SuccessMessage}</p>
      </NotificationModal>
    </div>
  );
});

export default Exchange;
