import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '../Icon/Icon';
import styles from './Menu.module.scss'; // Импортируем стили как модуль

import servicesIcon from '../../images/Work.svg';
import servicesIconActive from '../../images/Work_active.svg';
import requestsIcon from '../../images/Tick Square.svg';
import requestsIconActive from '../../images/Tick Square_active.svg';
import refsIcon from '../../images/Chart.svg';
import refsIconActive from '../../images/Chart_active.svg';
import profileIcon from '../../images/Profile.svg';
import profileIconActive from '../../images/Profile_active.svg';

function Menu() {
    const location = useLocation();
    const navigate = useNavigate();

    const menuItems = [
        {
            id: 'services',
            label: 'Услуги',
            path: '/',
            iconDefault: servicesIcon,
            iconActive: servicesIconActive
        },
        {
            id: 'requests',
            label: 'Заявки',
            path: '/requests',
            iconDefault: requestsIcon,
            iconActive: requestsIconActive
        },
        {
            id: 'refs',
            label: 'Рефы',
            path: '/refs',
            iconDefault: refsIcon,
            iconActive: refsIconActive
        },
        {
            id: 'profile',
            label: 'Профиль',
            path: '/profile',
            iconDefault: profileIcon,
            iconActive: profileIconActive
        }
    ];
    const [activeMenuItem, setActiveMenuItem] = useState(menuItems[0].id);
    return (
        <div className={styles.menu}>
            {menuItems.map(item => {
                const isActive = (activeMenuItem === item.id);

                return (
                    <div
                        key={item.id}
                        className={`${styles.menuItem} ${isActive ? styles.menuItemActive : ''}`}
                        onClick={() => {
                            setActiveMenuItem(item.id);
                            navigate(item.path);
                        }}
                    >
                        <Icon
                            src={isActive ? item.iconActive : item.iconDefault}
                            alt={item.label}
                        />
                        <span className={styles.menuLabel}>{item.label}</span>
                    </div>
                );
            })}
        </div>
    );
}

export default Menu;
