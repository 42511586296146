import './Button.scss';

const Button = ({ type, onClick, label = '', disabled, icon = null, iconPosition = 'right', }) => {
  let buttonClassName = 'btn';
  if (type === 'btnOne') buttonClassName += ' btnOne';
  else if (type === 'btnTwo') buttonClassName += ' btnTwo';
  else if (type === 'btnThree') buttonClassName += ' btnThree';

  return (
    <button
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && iconPosition === 'left' && (
        <span className="foex-button__icon foex-button__icon--left">
          {icon}
        </span>
      )}

      {label && (
        <span className="foex-button__label">
          {label}
        </span>
      )}

      {icon && iconPosition === 'right' && (
        <span className="foex-button__icon foex-button__icon--right">
          {icon}
        </span>
      )}
    </button>
  );
};

export default Button;