import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Menu from './components/Menu/Menu';
import ProfileScreen from './screens/ProfileScreen/ProfileScreen';
import RequestsScreen from './screens/RequestsScreen/RequestsScreen';
import Exchange from './components/Exchange/Exchange';
import AmlCheck from './components/AMLCheck/AMLCheck';
import Chat from './components/Chat/Chat';
import ServicesScreen from './screens/ServicesScreen/ServicesScreen';
import VED from './components/VED/VED';
import InvoicePayment from './components/InvoicePayment/InvoicePayment';
import RequestHistory from './components/RequestHistory/RequestHistory';
import webAppStore from './stores/WebAppStore';
import ReferralScreen from './screens/ReferralScreen/ReferralScreen';

const App = observer(() => {
  useEffect(() => {
    // Track initial visibility based on current path
    webAppStore.updateBackButtonVisibility();
    // Listen for history changes
    const handlePopState = () => {
      webAppStore.updateBackButtonVisibility();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<ServicesScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/requests" element={<RequestsScreen />} />
          <Route path="/create-request" element={<Exchange />} />
          <Route path="/aml-check" element={<AmlCheck />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/ved" element={<VED />} />
          <Route path="/invoice-payment" element={<InvoicePayment />} />
          <Route path="/refs" element={<ReferralScreen />} />
        </Routes>
        <Menu />
      </div>
    </Router>
  );
});

export default App;